<template>
    <div class="hfull">
      <div class="p-3" style="display: flex; justifycontent: right">
        <button class="btn btn-danger" @click="close">X</button>
      </div>
      <div class="header pt-lg-5">
        <b-container>
          <div class="header-body text-center mb-7">
            <b-row class="justify-content-center">
              <img
              src="../../../../public/img/brand/img-logo-color.png"
                alt="klhk"
                class="mb-2"
              />
              <b-col md="12">
                <h2 class="text-white">
                  PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                  MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                  DAN/ATAU KEGIATAN
                </h2>
                <p class="text-lead text-white">
                  Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                  validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                  bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                  Identification).
                </p>
              </b-col>
            </b-row>
            <button class="btn btn-primary" @click="showAnnoncementModal = true">
              LIHAT MEKANISME
            </button>
          </div>
        </b-container>
      </div>
  
      <b-container class="mt-5" >
      <b-row class="justify-content-center">
        <b-col>
          <b-card>
            <h3 class="my-5 text-center">Pendaftaran khusus yang memanfaatkan air limbah</h3>



            <div v-show="!isRegistered" class="p-5">
              <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label">ID Pendaftaran</label>
                <div class="col-lg-6">
                  <base-input
                    name="Id Pendaftaran"
                    v-model="idCekStatus"
                  ></base-input>
                  <small class="d-block mt--3 text-justify" v-if="!isRegistered">
                    Telusuri Riwayat Pendaftaran Sparing anda
                  </small>
                </div>
                <div class="col-lg-3" v-if="!isRegistered">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="cekSiupNumber(idCekStatus)"
                  >
                    <i class="ni ni-user-run"></i> CEK STATUS
                  </button>
                </div>
              </div>

              <div v-if="showHistory">
                <ValidationHistorys :data="histories" :is-loading-data="isLoadingData" @on-change-tab="getBackData" />
              </div>

              <p class="text-center">ATAU</p>
              <hr />

              <div class="col-lg-12 mb-3">
                <button
                  type="button"
                  class="btn btn-secondary w-100"
                  @click="editCompanyForm"
                >
                  Perubahan Data
                </button>
              </div>
              <div class="col-lg-12">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="newCompanyForm"
                >
                  Pendaftaran Baru
                </button>
              </div>
              </div>


              <!-- public information -->
              <div v-if="isRegistered">
                <div class="p-5">
                  <div class="form-group row">
                  <label class="col-lg-3 col-form-label form-control-label">Nomor SIUP</label>
                  <div class="col-lg-7">
                    <base-input
                      name="Nomor SIUP"
                      v-model="form.umum.numberSIUP"
                      rules="required"
                    ></base-input>
                  </div>
                  <!-- <div class="col-lg-2" v-if="!isRegistered">
                    <button
                      type="button"
                      class="btn btn-primary w-100"
                      @click="cekSiupNumber(form.umum.numberSIUP)"
                    >
                      <i class="ni ni-user-run"></i> CEK
                    </button>
                  </div> -->
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Permohonan Koneksi</label
                    >
                    <div class="col-lg-9">
                      <base-input name="File Permohonan Koneksi">
                        <file-input
                          accept="application/pdf"
                          ref="company_connection_request_file"
                          @change="
                            uploadFileBlob(formPublic,'company_connection_request_file')
                          "
                        ></file-input>
                      </base-input>
                      <small v-if="formPublic.company_connection_request_file" class="d-block mt-3 mb-2 text-justify">
                        <a :href="getBlobsFile(formPublic.company_connection_request_file)" target="_blank"
                          class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                      </small>
  
                      <small class="d-block  text-justify">
                        Surat permohonan pendaftaran koneksi sparing
                        kepada Direktur Pengendalian Pencemaran Air.
                      </small>
                      <span v-if="form.umum.requestConnect"
                        >Data anda Sebelumnya :
                        <a
                          :href="form.umum.requestConnect"
                          target="blank"
                          style="color: blue"
                          >klik untuk mendownload</a
                        ></span
                      >
                    </div>
                  </div>
  
                  <!-- 3. Nama Perusahaan -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Nama Perusahaan</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Nama Perusahaan"
                        rules="required"
                        v-model="form.umum.compName"
                      ></base-input>
                      <small class="d-block mt--4 text-justify">
                        Kata dan tanda baca wajib sama dengan Nama
                        Perusahaan pada SIMPEL PPA
                      </small>
                    </div>
                  </div>
  
                  <!-- 4. Jenis Industri -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Jenis Industri</label
                    >
                    <div class="col-lg-9">
                      <base-input name="Jenis Industri" rules="required">
                        <el-select v-model="form.umum.compType">
                          <el-option
                            v-for="option in provideSelect.companyType"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <!-- jika Tambang -->
                  <div
                    v-if="checkCompType(form.umum.compType)"
                    class="p-3 card border-0"
                    style="background: #ededed"
                  >
                    <div
                      class="mb-5 card-header px-4"
                      style="background: #ededed"
                    >
                      <!-- Detail -->
                    </div>
                    <div class="card-body p-3">
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-form-label form-control-label"
                          >Status Tambang</label
                        >
                        <div class="col-lg-9">
                          <base-input
                            name="Status Tambang"
                            rules="required"
                          >
                            <el-select
                              v-model="form.umum.ifTambang.status"
                            >
                              <el-option
                                label="Aktif"
                                value="Aktif"
                              ></el-option>
                              <el-option
                                label="Reklamasi"
                                value="Reklamasi"
                              ></el-option>
                              <el-option
                                label="Pasca Tambang"
                                value="Pasca Tambang"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
  
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-form-label form-control-label"
                          >Jenis Kegiatan</label
                        >
                        <div class="col-lg-9">
                          <base-input
                            name="Jenis Kegiatan"
                            rules="required"
                          >
                            <el-select
                              v-model="form.umum.ifTambang.jenKegiatan"
                            >
                              <el-option
                                label="Pengolahan"
                                value="Pengolahan"
                              ></el-option>
                              <el-option
                                label="Penambangan"
                                value="Penambangan"
                              ></el-option>
                              <el-option
                                label="Pengolahan & Penambangan"
                                value="Pengolahan & Penambangan"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
  
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-form-label form-control-label"
                        >
                          Rencana penetapan titik penaatan
                          <i>(complience point)</i> sampai dengan 5 tahun
                          kedepan (dokumen berupa tabel perencanaan)
                        </label>
                        <div class="col-lg-9">
                          <base-input
                            name="Rencana penetapan titik penaatan"
                          >
                            <file-input
                              accept="application/pdf"
                              ref="company_mine_compliance_point_file"
                              @change="uploadFileBlob(formPublic, 'company_mine_compliance_point_file')"
                            ></file-input>
                          </base-input>
                          <small v-if="formPublic.company_mine_compliance_point_file"
                              class="d-block mt-3 mb-2 text-justify">
                              <a :href="getBlobsFile(formPublic.company_mine_compliance_point_file)"
                                target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                          </small>
                        </div>
                      </div>
  
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-form-label form-control-label"
                          >Kondisi jaringan internet/GSM pada lokasi
                          remotearea</label
                        >
                        <div class="col-lg-9">
                          <html-editor
                            v-model="form.umum.ifTambang.kondisiInternet"
                          ></html-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End of Jika Tambang -->
  
                  <!-- 5. Nama penanggung Jawab SIUP -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Nama Penanggung Jawab SIUP</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Nama Penanggung Jawab SIUP"
                        rules="required"
                        v-model="form.umum.nameSIUP"
                      ></base-input>
                    </div>
                  </div>
  
                  <!-- 6. Lampiran SIUP -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Lampiran SIUP</label
                    >
                    <div class="col-lg-9">
                      <base-input name="Lampiran SIUP">
                        <file-input
                          accept="application/pdf"
                          ref="company_siup_file"
                          @change="uploadFileBlob(formPublic,'company_siup_file')"
                        ></file-input>
                      </base-input>
                      <small v-if="formPublic.company_siup_file"
                          class="d-block mt-3 mb-2 text-justify">
                          <a :href="getBlobsFile(formPublic.company_siup_file)"
                            target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                      </small>
                    </div>
                  </div>
  
                  <!-- 7. Alamat perusahaan -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Alamat Perusahaan</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Alamat Perusahaan"
                        rules="required"
                        v-model="form.umum.compAddress"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Bagi Industri yang memiliki alamat berbeda antara
                        kantor pusat dan kantor kegiatan (Unit
                        Bisnis/site), Mohon diisi alamat kantor unit
                        bisnis
                      </small>
                    </div>
                  </div>
  
                  <!-- 8. Jalan -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Jalan</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Jalan"
                        rules="required"
                        v-model="form.umum.compStreet"
                      ></base-input>
                    </div>
                  </div>
  
                  <!-- 9. Provinsi -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Provinsi</label
                    >
                    <div class="col-lg-9">
                      <base-input name="Provinsi" rules="required">
                        <el-select
                          v-model="form.umum.compProvince"
                          @change="
                            getCityDepentOnProvince(
                              form.umum.compProvince
                            )
                          "
                          filterable
                        >
                          <el-option
                            v-for="option in provideSelect.provinsi"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
  
                  <!-- 10. Kabupaten kota -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Kabupaten / Kota</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Kabupaten / Kota"
                        rules="required"
                      >
                        <el-select
                          v-model="form.umum.compCity"
                          filterable
                        >
                          <el-option
                            v-for="option in provideSelect.kabupatenKota"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
  
                  <!-- 11. Telepon Kantor -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Telepon Kantor</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Telepon Kantor"
                        rules="required"
                        v-model="form.umum.compPhone"
                        type="tel"
                      />
                    </div>
                  </div>
  
                  <!-- 12. Email kantor -->
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Email Kantor</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Email Kantor"
                        rules="required"
                        v-model="form.umum.compMail"
                        type="email"
                        autocomplete="username email"
                      />
                    </div>
                  </div>
  
                  <h3 class="my-5 text-center">Informasi Pengguna</h3>
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Nama Kontak Person</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Nama Kontak Person"
                        rules="required"
                        v-model="form.akun.personName"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Personil yang menangani pengolahan air limbah dan
                        terkait data SPARING
                      </small>
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Nomor Handphone</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Nomor Handphone"
                        rules="required"
                        v-model="form.akun.personPhone"
                        type="tel"
                      />
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Alamat</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Alamat user"
                        rules="required"
                        v-model="form.akun.personAddress"
                        type="tel"
                      />
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Email Kontak Person</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Email Kontak Person"
                        rules="required"
                        v-model="form.akun.personMail"
                        type="email"
                        autocomplete="username email"
                      />
                      <base-input
                        name="Email Kontak Person ID"
                        type="hidden"
                        v-model="form.akun.personMailID"
                      ></base-input>
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Password</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Password"
                        rules="required"
                        v-model="form.akun.personPassword"
                        type="password"
                      />
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                      >Confirm Password</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Confirm Password"
                        rules="required"
                        v-model="form.akun.personConfirmPassword"
                        type="password"
                      />
                    </div>
                  </div>
                </div>
                <!-- end of public information -->
  
                <div class="p-5">
                  <div class="mb-5">
                    <hr>
                    <b>Khusus Industri Yang Menfaatkan Air Limbah 100% Ke Proses Produksi</b>
                  </div>
                  <div>
  
                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                      <b-form role="form" @submit.prevent="handleSubmit(submitRegisterWaste)">
                        <!-- Debit Penggunaan air -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Penggunaan Air</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Penggunaan Air" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitPenggunaan.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Penggunaan Air" rules="required">
                              <el-select v-model="form.prosesProduksi.debitPenggunaan.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Penggunaan Air" placeholder="Keterangan (Proses dan Teknologi yang digunakan) " rules="required" v-model=" form.prosesProduksi.debitPenggunaan.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah total -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Total</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Total" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Total" rules="required">
                              <el-select v-model="form.prosesProduksi.debitLimbahTotal.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Total" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air reuse -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Reuse</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Reuse" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitReuse.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Reuse" rules="required">
                              <el-select v-model="form.prosesProduksi.debitReuse.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Reuse" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitReuse.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air recycle -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recycle</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recycle" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecycle.value"></base-input>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recycle" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecycle.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Recycle" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecycle.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air Recovery -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recovery</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recovery" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecovery.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recovery" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecovery.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecovery.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah diolah IPAL -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Diolah IPAL</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Diolah IPAL" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Diolah IPAL" rules="required">
                              <el-select v-model="form.prosesProduksi.debitOlahIPAL.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Diolah IPAL" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.keterangan"
                            />
                          </div>
                        </div>
    
                        <!-- Unggah Neraca air dan unggaj air limbah -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Unggah Neraca Air Dan Unggah Air Limbah</label>
                          <div class="col-lg-10">
                            <base-input  name="File Neraca Air Dan Unggah Air Limbah">
                              <file-input
                                accept="application/pdf"
                                ref="water_and_wastewater_balance_file"
                                @change="uploadFileBlob(file.pengolahan_kembali,'water_and_wastewater_balance_file')"
                              ></file-input>
                            </base-input>
                            <small v-if="file.pengolahan_kembali.water_and_wastewater_balance_file"
                                class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(file.pengolahan_kembali.water_and_wastewater_balance_file)"
                                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>
                          </div>
                        </div>
                        <div style="display: flex;justify-content: center;">
                          <button class="btn btn-primary mt-5" type="submit">KIRIM</button>
                          <button class="btn btn-danger mt-5" type="button" @click="cancelSubmit">BATAL</button>
                        </div>
                      </b-form>
                    </validation-observer>
  
                  </div>
                </div>
              </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="announcement-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img
            src="../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contents">
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>


    
  
     
    </div>
  </template>
  
  <script>
  import API from "@/api/base_url.js";
  import FileInput from "@/components/Inputs/FileInput";
  import "flatpickr/dist/flatpickr.css";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import BaseHeader from "@/components/BaseHeader";
  import baseURL from "@/api/base_url_backend.js";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import ValidationHistorys from "./HistorySparing.vue";

  
  export default {
    name: "pendaftaranSparing",
    components: {
      ValidationHistorys,
      BaseHeader,
      FileInput,
      HtmlEditor,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      flatPicker,
    },
    mounted() {
      this.getCompanyType()
      this.getProvince()
    },
    data() {
      return {
        listDataHistory: "",
        showHistory: "",
        idCekStatus:"",
        isRegistered:false,
        formPublic: {
          company_connection_request_file:"",
          company_mine_compliance_point_file:"",
          company_siup_file: "",
        },
        form_permits: {
        attachment_file_id: "",
        issuing_authority: "",
        no: "",
        publication_date: "",
        expiration_date: ""
        },
        userInput: {
        company_connection_request_file: "",
        company_siup_file: "",
        company_mine_compliance_point_file: "",
        company_address: "",
        company_address_alternative: "",
        company_email: "",
        company_name: "",
        company_phone: "",
        company_province_id: "",
        company_regency_id: "",
        company_siup_number: "",
        company_siup_on_behalf: "",
        company_type_id: "",
        user_email: "",
        user_name: "",
        user_password: "",
        company_mine_activity_type: "",
        company_mine_network_status: "",
        company_mine_status: "",
        user_address: "",
        user_phone: "",
        user_confirm_password: "",
        },
        selects: {
          configs: {
            flatpickr: {
              allowInput: true,
              altFormat: "Y-m-d",
              altInput: false,
              dateFormat: "Y-m-d",
              mode: "single"
            }
          }
        },
        allowedCompTypes: [
        "Pertambangan Emas dan Tembaga",
        "Pertambangan Batu Bara",
        "Pertambangan Nikel",
      ],
        file: {
          pengolahan_kembali: {
            water_and_wastewater_balance_file: ''
          }
        },
        showFormRegisterWaste: false,
        showForm: false,
        showAnnoncementModal: false,
        provideSelect: {
          provinsi: [],
          kabupatenKota: [],
          daftarPerusahaan: [],
          tmp: [],
          uploadChoosen: null,
          companyType: [],
        },
        form: {
          akun: {
            personName: null,
            personAddress: "",
            personPhone: null,
            personMail: null,
            personMailID: null,
            personPassword: null,
            personConfirmPassword: null,
          },
          umum: {
            requestConnect: "",
            filedownload: "",
            compName: null,
            compNameID: null,
            compType: null,
            compAddress: null,
            compStreet: null,
            compCity: null,
            compProvince: null,
            compPhone: null,
            compMail: null,
            numberSIUP: null,
            nameSIUP: null,
            ifTambang: {
              status: "",
              jenKegiatan: "",
              kondisiInternet: "",
            },
            rencanaFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            siupFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            compPermohonanKoneksi: {
              path: "",
              upload: 0,
              progress: 0,
            },
            updatedAt: 0,
            createdAt: null,
          },
          prosesProduksi: {
            debitPenggunaan: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitLimbahTotal: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitReuse: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecycle: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecovery: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitOlahIPAL: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            fileNeracaAirLimbah: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
        },
        formData: new FormData(),
        debitUnit: ['m3/detik','m3/menit','m3/jam','m3/hari','m3/bulan']
      };
    },
    methods: {
      cancelSubmit() {
        this.isRegistered = false
      },
      editCompanyForm() {
        this.showHistory = false
        this.idCekStatus = ""

        Swal.fire({
          title: "ID Pendaftaran Sparing",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "CARI",
          cancelButtonText: "BATAL",
          showLoaderOnConfirm: true,
          preConfirm: async (id) => {
            try {

              API.get(`auth/register/waste-recycle/${id}`)
              .then(({data:content}) => {
                this.listData = content.data
                this.isLoading = false
              
                this.$router.push(`/sparing/pendaftaran-pengolahan-air-kembali/perubahan/${id}`);

                return "Data ditemukan"
              })
              .catch((err) => {
                console.log(err);

                if (err.response.data == '404 page not found\n') {
                  Swal.showValidationMessage(`Server gagal Merespon`);
                  return "Server gagal Merespon"
                } else if (err.response.data.meta.message == 'Sparing not found') {
                  Swal.showValidationMessage(`Data tidak ditemukan`);
                  return "Data tidak ditemukan"
                } else {
                  Swal.showValidationMessage(`Server gagal Merespon`);
                  return "Server gagal Merespon"
                }
              })

            } catch (error) {
              Swal.showValidationMessage(`Request failed: ${error}`);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            Swal.fire({
              title: '',
            });
          }
        });
      },
      newCompanyForm() {
        this.idCekStatus = ""
        this.showHistory = false
        this.isRegistered = true;
      },
      seeRiwayatValidasi() {
        this.getBackData('validasi')
        this.showAnnoncementModalHistory = true
      },
      async getValidationHistory() {
        let tmp = this.listDataHistory.approval_histories
        this.histories = []


        if (tmp == null || tmp == undefined) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              user_name:tmp[i].user_name,
              created_time_user: this.formatDate(tmp[i].registration_time),
              keterangan: tmp[i].keterangan,
              admin_name:tmp[i].admin_name,
              created_time: this.formatDate(tmp[i].approval_time),
              status:tmp[i].status
            })
          }
        }

        tmp = []
        return  this.histories
        
      },
      
      async getChangesData() {
        console.log("Change Data");

        let tmp = this.listDataHistory.doc_histories

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              // no:1,
              name:tmp[i].field_name,
              updatedAt:this.formatDate(tmp[i].created_at),
              oldData:tmp[i].old_value,
              newData:tmp[i].new_value,
            })
          }
        }

        return  this.histories
      },

      async getChangesDocument() {
        console.log("Change Dokumen");
        
        let tmp = this.listDataHistory.doc_histories

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              no: 1,
              documentName: tmp[i].field_name,
              documentType: 'doc.tipe_dokumen',
              updatedAt: this.formatDate(tmp[i].created_at),
              DocumentPreviously: tmp[i].old_value,
              newDocument: tmp[i].new_value,
            })
          }
        }

        return  this.histories
      },

      getBackData(type) {
          if (type === 'validasi') {
              this.histories = []

              setTimeout(() => {
                this.getValidationHistory()
              }, 100);
          }

          if (type === 'perubahanData') {
              this.histories = []

              setTimeout(() => {
                this.getChangesData()
              }, 100);
          }

          if (type === 'perubahanDokumen') {
              this.histories = []

              setTimeout(() => {
                this.getChangesDocument()
              }, 100);
          }
      },
      cekSiupNumber(siupNumber) {
        this.showHistory = false
        API.get(`auth/register/waste-recycle/${siupNumber}/check-status`)
          .then(({ data }) => {
            this.listDataHistory = data.data

            this.showHistory = true
            // this.seeRiwayatValidasi()
            this.getValidationHistory()
            // if (data.data.is_registered) {
            //   Swal.fire(
            //     "SIUP Terdaftar",
            //     "Anda dapat menggunakan data anda sebelumnya",
            //     "info"
            //   );
            //   this.$router.push("/login");
            // } else {
            //   Swal.fire("Data tidak ditemukan", "", "info");
            // }
          })
          .catch((err) => {
            Swal.fire("Data tidak ditemukan","Anda belum memiliki pendaftaran Sparing","info");
          });
      },
      getCompanyType() {
        API.get(`comp-types`)
          .then(({ data: content }) => {
          this.provideSelect.companyType = content.data
          console.log(content.data);
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Jenis Perusahaan", "", "error");
          });
      },
      getProvince() {
        API.get(`provinces`)
          .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data
          })
          .catch((err) => {
            Swal.fire("Gagal Mendapatkan Data Provinsi", "", "error");
          });
      },
      getCityDepentOnProvince(data) {
        this.form.umum.compCity = []
        this.provideSelect.kabupatenKota = [];
        API.get(`kabkots?prov_id=${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      uploadFile(field) {
        // ${defaults.baseURL}/file
        const fileInput = this.$refs[field];
        const file = fileInput.files[0];
        const formData = new FormData();
        formData.append("file", file);
        this.uploading = true;
        API.post(`blobs/upload/public`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            let data = res.data.data;
            let value = data.id;
            this.$set(this.userInput, field, value);

            // console.log(`${data}:`, this.userInput[field]);
            this.uploading = false;
          })
          .catch((err) => {
            this.uploading = false;
            Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
          });
      },
      checkCompType(compType) {
        // Ambil label berdasarkan compType
        const label = this.provideSelect.companyType.find(
          (option) => option.id === compType
        )?.name;
        if (label) {
          console.log(label);
          return this.allowedCompTypes.includes(label);
        }
      },
      // start upload
      uploadFileBlob(form, field) {
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
      },
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
      },
      resetAllFileInputs(refs) {
        refs.forEach(ref => this.resetFileInput(ref));
      },
      // end of upload
      goBack() {
        this.$router.push("/pengolahan-kembali/air-limbah");
      },
      close() {
        this.$router.push("/pilih-pendaftaran");
      },
      submitRegisterWaste() {
        Swal.fire({
          title: "Kirim Pemanfaatan air kembali?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM",
          cancelButtonText: "BATAL"
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              company: {
                address: this.form.umum.compAddress,
                email: this.form.umum.compMail,
                kabkot_id: this.form.umum.compCity,
                koneksi_file_id: this.formPublic.company_connection_request_file,
                mine: {
                  activity_type: this.form.umum.ifTambang.jenKegiatan,
                  network_cond: this.form.umum.ifTambang.kondisiInternet,
                  rencana_file_id:this.formPublic.company_mine_compliance_point_file,
                  status: this.form.umum.ifTambang.status,
                },
                name: this.form.umum.compName,
                phone: this.form.umum.compPhone,
                prov_id: this.form.umum.compProvince,
                siup_file_id: this.formPublic.company_siup_file,
                siup_no: this.form.umum.numberSIUP,
                siup_pj: this.form.umum.nameSIUP,
                street: this.form.umum.compStreet,
                type_id: this.form.umum.compType,
              },
              user: {
                email: this.form.akun.personMail,
                name: this.form.akun.personName,
                password: this.form.akun.personPassword,
                phone: this.form.akun.personPhone,
              },
              waste_recycle: {
                attachment_file_id: this.file.pengolahan_kembali.water_and_wastewater_balance_file,
                debit_limbah_total: {
                  desc: this.form.prosesProduksi.debitLimbahTotal.keterangan ,
                  unit: this.form.prosesProduksi.debitLimbahTotal.satuan,
                  value: +this.form.prosesProduksi.debitLimbahTotal.value
                },
                debit_olah_ipal: {
                  desc: this.form.prosesProduksi.debitOlahIPAL.keterangan,
                  unit: this.form.prosesProduksi.debitOlahIPAL.satuan,
                  value: +this.form.prosesProduksi.debitOlahIPAL.value
                },
                debit_penggunaan: {
                  desc: this.form.prosesProduksi.debitPenggunaan.keterangan,
                  unit: this.form.prosesProduksi.debitPenggunaan.satuan,
                  value: +this.form.prosesProduksi.debitPenggunaan.value
                },
                debit_recovery: {
                  desc: this.form.prosesProduksi.debitRecovery.keterangan,
                  unit: this.form.prosesProduksi.debitRecovery.satuan,
                  value: +this.form.prosesProduksi.debitRecovery.value
                },
                debit_recycle: {
                  desc: this.form.prosesProduksi.debitRecycle.keterangan,
                  unit: this.form.prosesProduksi.debitRecycle.satuan ,
                  value: +this.form.prosesProduksi.debitRecycle.value 
                },
                debit_reuse: {
                  desc: this.form.prosesProduksi.debitReuse.keterangan,
                  unit: this.form.prosesProduksi.debitReuse.satuan ,
                  value: +this.form.prosesProduksi.debitReuse.value
                }
              },
            }

            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }

            console.log(payload);

            API.post(`auth/register/waste-recycle`, payload, { headers })
              .then(({data:content}) => {
                Swal.fire('Data Berhasil didaftarkan', '', 'success')
                this.resetForm()
                this.$router.push("/pilih-pendaftaran");

                this.formPublic = {
                  company_connection_request_file:"",
                  company_mine_compliance_point_file:"",
                  company_siup_file: "",
                }
              })
              .catch((err) => {
                Swal.fire('Pendaftaran Gagal', 'gagal mendaftarkan', 'error')
              })
          }
        });
      },
      resetForm() {
        this.form = {
          prosesProduksi: {
            debitPenggunaan: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitLimbahTotal: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitReuse: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecycle: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecovery: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitOlahIPAL: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            fileNeracaAirLimbah: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
        }
        this.formData = new FormData()
      }
    },
  };
  </script>
  

  